//export const BASE_URL=process.env.NODE_ENV='development'?'':'',
export const BASE_URL = "https://gym-project-zzlt.onrender.com";
export const GYMS_URL = "/api/gyms";
export const USER_URL = "/api/users";
export const USERSELF_URL = "/api/usersSelf";
export const ATTENDANCE_URL = "/api/attendance";
export const ASSOCIATE_ATTENDANCE_URL = "/api/associateAttendance";
export const PAYPAL_URL = "/api/payments";
export const RENEWAL_URL = "/api/renewals";
export const VISITORS_URL = "/api/visitors";
export const ASSOCIATES_URL = "/api/associates";
export const EXPENSES_URL = "/api/expenses";
export const GYM_PAYMENT = "/api/gymPaymentRoutes";
export const CUSTOMERS_URL = "/api/customers";
export const WS_MESSAGE_URL = "/api/wsMessages";
export const EQUIPMENT_URL = "/api/equipments";
export const GYMMETA_URL = "/api/gymMeta";
