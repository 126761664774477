import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { getCurrentDate } from "../utils/getCurrentDate";
import { setCredentials } from "../slices/authSlice";
import FormContainer from "../components/FormContainer";
import preferredLanguage from "../assets/preferredLanguage.json";
import welcomeMessage from "../assets/audios/messageTone.mp3";
import QRCodeGenerator from "../utils/createQRcode";
import {
  useGetAssociatesAttendanceQuery,
  useUpdateAssociateAttendanceMutation,
} from "../slices/associatesSlice";
import errorTone from "../assets/audios/error_tone.mp3";


const AssociateAttendanceScreen = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [associateId, setAssociateId] = useState();
  const selectedLanguage = userInfo?.preferredLanguage
    ? userInfo?.preferredLanguage
    : "English";
  const offset = 5 * 60 * 60 * 1000 + 30 * 60 * 1000;

  const [startDate, setStartDate] = useState(getCurrentDate());

  const [endDate, setEndDate] = useState(getCurrentDate());

  let startDate1 =
    new Date(`${getCurrentDate()}T00:00:00.567Z`).getTime() - offset;

  let endDate1 =
    new Date(`${getCurrentDate()}T23:59:59.567Z`).getTime() - offset;

  const [getApiQuery, setGetApiQuery] = useState(
    `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}&sort=-timeIn`
  );
  const qrContent = `https://qa.zpotter.in/associateAttendanceFromMobile/${userInfo.gymId._id}`;
  const [refreshData, setRefreshData] = useState(false);

  const {
    data: attendances,
    isLoading,
    error,
    refetch,
  } = useGetAssociatesAttendanceQuery({
    query: getApiQuery,
    token: userInfo ? userInfo.token : "Bearer a",
  });

  const [usersToList, setUsersToList] = useState(
    isLoading ? [] : attendances ? attendances : []
  );
  const [todaysUsers, setTodaysUsers] = useState(
    isLoading ? [] : attendances ? attendances.length : 0
  );

  const [currentUsersAtGym, setCurrentUsersAtGym] = useState(0);
  const [updateAttendance, { isUpdateAttendanceLoading }] =
    useUpdateAssociateAttendanceMutation();

  const dispatch = useDispatch();

  

  const handleGetUsers = async () => {
    if (startDate > getCurrentDate())
      toast.error("Start date can not be greater than today");
    else if (startDate > endDate)
      toast.error("Start date can not be greater than End date");
    else {
      startDate1 = new Date(`${startDate}T00:00:00.567Z`).getTime() - offset;
      endDate1 = new Date(`${endDate}T23:59:59.567Z`).getTime() - offset;

      setGetApiQuery(
        `?gymId=${userInfo.gymId._id}&timeIn[gte]=${startDate1}&timeIn[lte]=${endDate1}&sort=-timeIn`
      );
      dispatch(
        setCredentials({
          ...userInfo,
          attendancesStartDate: startDate,
          attendancesEndDate: endDate,
        })
      );
    }
  };

  useEffect(() => {
    if (error?.data?.error) {
      setTodaysUsers("");
    }
  }, [error]);

  useEffect(() => {
    refetch();
    const todaysUsers = isLoading ? [] : attendances ? attendances : [];
    setUsersToList(todaysUsers);
    const currentUsers = isLoading
      ? []
      : attendances
      ? attendances.filter((att) => {
          return att.timeIn >= Date.now() - 3600000 && !att.timeOut;
        })
      : [];
    setCurrentUsersAtGym(currentUsers);
  }, [setTodaysUsers, attendances, refreshData]);

  const handleFilterSelecttion = (val) => {
    setUsersToList(val);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!userInfo) toast.error("Please login to use the application");
    if (
      associateId?.toString().length < 1 ||
      associateId?.toString().length > 999999 ||
      !associateId
    )
      toast.error("Please enter valid Member Id");
    else {
      try {
        const res = await updateAttendance({
          payload: {
            associateId: associateId,
          },
          token: userInfo.token,
        }).unwrap();

        toast.success(() => (
          <div>
            <audio src={welcomeMessage} type="audio/mp3"></audio>
            <br />
            {res?.message}
            <br />
            <br />
            <strong>
              Thank You {res?.data?.compositeId?.toString().substring(4)}
            </strong>
          </div>
        ));

        setAssociateId("");
        setRefreshData(!refreshData);
      } catch (err) {
        err.status === "PARSING_ERROR" || err.status === 400
          ? toast.error(() => (
              <div>
                <audio src={errorTone} type="audio/mp3"></audio>
                <br />
                Please enter valid Employee ID
              </div>
            ))
          : toast.error(err.error);
      }
    }
  };
  const deviceOs = navigator?.userAgent;

  return (
    <>
      {" "}
      <label>
        <strong>
          {" "}
          {preferredLanguage[selectedLanguage]?.associateAttendanceList}
        </strong>
      </label>
      {deviceOs.includes("Mac") || deviceOs.includes("Window") ? (
        <Row>
          <Col>
            <FormContainer>
              <Form onSubmit={submitHandler}>
                <Form.Group className="my-2" controlId="userId">
                  <Form.Label id="userId">
                    {
                      preferredLanguage[selectedLanguage]?.attendancePage
                        .typeAssociateId
                    }
                  </Form.Label>
                  <Form.Control
                    style={{ height: "50px" }}
                    type="text"
                    placeholder="Member Id"
                    autoComplete="off"
                    autoFocus
                    value={associateId || ""}
                    onChange={(e) => {
                      // Update userId only if input is alphanumeric (no special characters)
                      const newValue = e.target.value.replace(
                        /[^a-zA-Z0-9]/g,
                        ""
                      ); // Allow only letters and numbers
                      setAssociateId(newValue);
                    }}
                  />
                </Form.Group>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    className={`${userInfo.themeColor}ThemeHeaderTop`}
                    type="submit"
                    variant="primary"
                  >
                    {preferredLanguage[selectedLanguage]?.attendancePage.submit}
                  </Button>
                </div>
                {isLoading && <Loader />}
              </Form>{" "}
            </FormContainer>
          </Col>
          <Col>
            <div
              style={{
                width: "200px",
                height: "200px",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  marginLeft: "50px",
                }}
              >
                <h6>Scan to mark Attendance</h6>
                <QRCodeGenerator content={qrContent} />
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
      <Row
        className="mb-1"
        style={{
          justifyContent: "center",
          margin: "auto",
          width: "95%",
          alignItems: "center",
        }}
      >
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-23"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={6} md="auto" sm={6}>
          <Form.Group controlId="visitorMobile">
            <Form.Control
              type="date"
              placeholder="2023-11-27"
              value={endDate?.split("T")[0]}
              onChange={(e) => setEndDate(e.target.value)}
            ></Form.Control>{" "}
          </Form.Group>
        </Col>
        <Col xs={12} md="auto" sm={6} className="d-flex justify-content-center">
          <Form.Group className="my-1" controlId="gymType">
            <Button
              className={`${userInfo.themeColor}ThemeHeaderTop`}
              onClick={handleGetUsers}
            >
              Get Data
            </Button>
          </Form.Group>
        </Col>
      </Row>
      {userInfo.role === "owner" ||
      userInfo.role === "Super Admin" ||
      userInfo.role === "admin" ? (
        <>
          <Row
            className="dataRow"
            style={{
              justifyContent: "center",
              marginBottom: "2%",
            }}
          >
            <Button
              className="marginPointOne sixteenWidth buttonGreen numbersButton"
              onClick={() => handleFilterSelecttion(currentUsersAtGym)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.atGym}{" "}
              <strong> {currentUsersAtGym?.length}</strong>
            </Button>
            <Button
              className="marginPointOne sixteenWidth buttonOrchid numbersButton"
              onClick={() => handleFilterSelecttion(attendances)}
            >
              {preferredLanguage[selectedLanguage]?.attendanceListPage.total}{" "}
              <strong> {attendances?.length}</strong>
            </Button>
          </Row>
        </>
      ) : (
        ""
      )}
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message>{error?.data?.error}</Message>
      ) : usersToList ? (
        <div style={{ height: "100vh" }}>
          <div className="tableContainer">
            <Table
              striped
              bordered
              hover
              responsive="sm"
              className="table-custom"
            >
              <thead className={`${userInfo.themeColor}ThemeThead`}>
                <tr>
                  <th>Name</th>
                  <th>Id</th>
                  <th>Date</th>
                  <th>In</th>
                  <th>Out</th>
                  <th>Work Hour</th>
                </tr>
              </thead>
              <tbody className={`${userInfo.themeColor}ThemeTbody`}>
                {usersToList.map((user) => (
                  <tr>
                    <td>{user.compositeId?.firstName} </td>
                    <td>{user.compositeId?._id?.toString().substring(4)}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      {new Date(user.timeIn).getFullYear() +
                        "-" +
                        (new Date(user.timeIn).getMonth() + 1)
                          .toString()
                          .padStart(2, "0") +
                        "-" +
                        new Date(user.timeIn)
                          .getDate()
                          .toString()
                          .padStart(2, "0")}
                    </td>
                    <td>
                      {" "}
                      {new Date(user.timeIn).getHours() +
                        ":" +
                        (new Date(user.timeIn).getMinutes() < 10
                          ? "0" + new Date(user.timeIn).getMinutes()
                          : new Date(user.timeIn).getMinutes())}{" "}
                      {`(${user.inMode})`}
                    </td>
                    <td>
                      {" "}
                      {user.timeOut
                        ? new Date(user.timeOut).getHours() +
                          ":" +
                          (new Date(user.timeOut).getMinutes() < 10
                            ? "0" + new Date(user.timeOut).getMinutes()
                            : new Date(user.timeOut).getMinutes() +
                              ` (${user.outMode})`)
                        : "not marked"}
                    </td>
                    <td>
                      {user.timeOut
                        ? (
                            (user?.timeOut - user?.timeIn) /
                            (1000 * 60 * 60)
                          ).toFixed(1)
                        : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AssociateAttendanceScreen;
